import React, { Component } from "react";
import {
  Layout,
  Pagination,
  Table,
  Menu,
  Dropdown,
  Spin,
  message,
  Tooltip,
} from "antd";
import axios from "axios";
import { Input, DatePicker } from "antd";
import "./VehicleDetails.css";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Content } = Layout;
const { Search } = Input;
const dateFormat = "DD-MM-YYYY, hh:mm A";
const timeFormat = "hh:mm A";
const { RangePicker } = DatePicker;

class VehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      vehicleCustomerDetails: [],
      rsaHistory: [],
      serviceRecommendations: [],
      tripDetails: [],
      tripDetailsSubArray: [],
      vehicleAlertDetails: [],
      vehicleAlertDetailsSubArray: [],
      vehicleHealthStatus: [],
      upcomingService: [],
      loader: false,
      serviceItemandAction: [],
      upcomingServiceBookings: [],
      rsaStatus: [],
      notification: [],
      auditTrail: [],
      auditTrailSubArray: [],
      vehicleDetails: [],
      pageNumberAlert: 0,
      pageNumberHealth: 0,
      pageNumberTrip: 0,
      pageNumberRSA: 0,
      pageNumberRsaStatus: 0,
      pageNumberUpcoming: 0,
      pageNumberService: 0,
      pageNumberServiceItem: 0,
      pageNumberNotification: 0,
      startDate: "",
    };

    this.tripDetailsColumns = [
      {
        title: "Device number",
        dataIndex: "din",
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (record) => (
          <span>
            {record === null ? (
              <span>-</span>
            ) : (
              <Moment format={dateFormat}>{record}</Moment>
            )}
          </span>
        ),
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        render: (record) => (
          <span>
            {record === null ? (
              <span>-</span>
            ) : (
              <Moment format={dateFormat}>{record}</Moment>
            )}
          </span>
        ),
      },
      {
        title: "Start Location",
        dataIndex: "start_location",
        onCell: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              maxWidth: 150,
            },
          };
        },
        render: (text) => (
          <Tooltip title={text}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {text}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "End Location",
        dataIndex: "end_location",
        onCell: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              maxWidth: 150,
            },
          };
        },
        render: (text) => (
          <Tooltip title={text}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {text}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Distance Driven",
        dataIndex: "distance_driven",
      },
      // {
      //   title: "Trip Score",
      //   dataIndex: "trip_score",
      // },
      // {
      //   title: "Poly String",
      //   dataIndex: "poly_string",
      //   onCell: () => {
      //     return {
      //       style: {
      //         whiteSpace: "nowrap",
      //         maxWidth: 150,
      //       },
      //     };
      //   },
      //   render: (text) => (
      //     <Tooltip title={text}>
      //       <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
      //         {text}
      //       </div>
      //     </Tooltip>
      //   ),
      // },
    ];
  }

  componentDidMount(props) {



    // if (this.props.match.path != "/vehicle-details") {
    var url = new URL(window.location);
    var pathValue = url.pathname;
    var hubIdUrl = pathValue.split("/");
    if (hubIdUrl[2] != undefined) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/trip_summary`,
          {
            deviceId: hubIdUrl[2],
          }
        )
        .then((result) => {
          if (Array.isArray(result.data.data) && result.data.data.length) {

            this.setState({
              tripDetails: result.data.data,
              tripDetailsSubArray: result.data.data,
            });
          } else {
            toast.error(
              "No records found for this device number, please try with a different one",
              {
                hideProgressBar: true,
              }
            );
          }
        })
        .catch((error) => { });
    }
    axios
    .get(
      `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/all_trips`
    )
    .then((result) => {
      if (Array.isArray(result.data.data) && result.data.data.length) {

        this.setState({
          tripDetails: result.data.data,
          tripDetailsSubArray: result.data.data,
        });
      } else {
        toast.error(
          "No records found for this device number, please try with a different one",
          {
            hideProgressBar: true,
          }
        );
      }
    })
    .catch((error) => { });
  }

  onSearch(value) {
    this.setState({
      loader: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/trip_summary`,
        {
          deviceId: value,
        }
      )
      .then((result) => {
        // console.log("hmmm", Array.isArray(result.data.data))
        if (Array.isArray(result.data.data) && result.data.data.length) {

          this.setState({
            tripDetails: result.data.data,
            tripDetailsSubArray: result.data.data,
          });
        } else {
          toast.error(
            "No records found for this device number, please try with a different one",
            {
              hideProgressBar: true,
            }
          );
        }
      })
      .catch((error) => { });
  }

  onTripDetailsDateChange = (value, dateString) => {
    let startDate = dateString[0];
    let endDate = dateString[1];
    let data = this.state.tripDetails;
    let result = data.filter(function (obj) {
      return obj.start_time >= startDate && obj.start_time <= endDate;
    });
    this.setState({
      tripDetailsSubArray: result,
    });
  };


  tripHistory = (e) => {
    let csvRow = [];
    let csvColumns = [
      [
        "Start Time	",
        "End Time",
        "Start Address",
        "End Address	",
        "Distance Driven	",
        "Trip Score	",
        "Poly String	",
      ],
    ];
    let temp = this.state.tripDetailsSubArray;
    for (let item = 0; item < temp.length; item++) {
      csvColumns.push([
        temp[item].start_time,
        temp[item].end_time,
        temp[item].start_address,
        temp[item].end_address,
        temp[item].distance_driven,
        temp[item].trip_score,
        temp[item].poly_string,
      ]);
    }
    for (let i = 0; i < csvColumns.length; ++i) {
      csvRow.push(csvColumns[i].join(","));
    }
    let csvString = csvRow.join("\n");
    let a = document.createElement("a");
    a.href = "data:attachment/csv," + encodeURI(csvString);
    a.target = "_Blank";
    a.download = "trip_history.csv";
    document.body.appendChild(a);
    a.click();
  };
  onRowClick = (record, rowIndex) => {
    this.setState({
      notification: record.notifications,
    });
  };
  setRowClassName = (record, rowIndex) => {
    return record.id === this.state.rowId ? "clickRowStyl" : "";
  };

  //pagination

  onTripDetailPageChange = (val) => {
    this.setState({ pageNumberTrip: val });
  };

  render() {

    const tripHistory = (
      <Menu onClick={(e) => this.tripHistory(e)}>
        <Menu.Item key="1">Download CSV</Menu.Item>
      </Menu>
    );


    return (
      <div>
        <Content>
          <ToastContainer autoClose={3000} hideProgressBar={true} />

          <div className="main-div">
            <Search
              placeholder="Device Number"
              onSearch={(e) => this.onSearch(e)}
              className="search-input"
              enterButton
            />
          </div>

          <div className="table-div">
            <div>
              <div className="header-menu">
                <div className="header">
                  {" "}
                  <p className="vehicle-health">Trip History and Behaviour</p>
                </div>
                <div className="vehicle-details-menu">
                  {" "}
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={this.onTripDetailsDateChange}
                  />
                  <Dropdown.Button
                    overlay={tripHistory}
                    className="vehicle-details-btn"
                  ></Dropdown.Button>
                </div>
              </div>
              <Table
                columns={this.tripDetailsColumns}
                dataSource={this.state.tripDetailsSubArray}
                // size="large"
                // scroll={{ y: 500 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.onRowClick(record, rowIndex);
                    },
                  };
                }}
                rowClassName={this.setRowClassName}
                pagination={{
                  pageSize: 5,
                  current: this.state.pageNumberTrip + 1,
                  defaultCurrent: 1,
                  onChange: (page) => this.onTripDetailPageChange(page - 1),
                }}
              />
            </div>


          </div>
        </Content>
      </div>
    );
  }
}
export default VehicleDetails;
