import React, { Component } from "react";
//import { View, Text } from 'react-native';
import { Layout } from "antd";
import Iframe from "react-iframe";
// import { REPORT_LINK } from '../../constants/config';

const { Content } = Layout;

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Content>
          <Iframe
            className="report-css"
            src="https://datastudio.google.com/embed/reporting/885c9932-6e1f-4603-b958-9c24b8a5e242/page/jWZYB"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></Iframe>
        </Content>
      </div>
    );
  }
}
export default DashboardComponent;
