import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal, Select } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";

const MappingTable = ({ data, cancelModal }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [mappingData, setMappingData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [modelData, setModelData] = useState([]);

  const [formData, setFormData] = useState({
    customer_name: '',
    machine_model_name: '',
    DIN: '',
    machine_number: '',
    errors: {
      customer_name: '',
      mobile_number: '',
      DIN: '',
      machine_number: '',
      customerId: '',
      machineModelId:'',
      deviceId:''
    }
  });

  useEffect(() => {
    listMapping();
    listDevices();
    listModel();
    listCustomer();
    setModalVisible(data);
  }, [data]);

  const listCustomer = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_customers`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setCustomerData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const listDevices = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_devices`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setDeviceData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const listModel = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_models`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setModelData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const listMapping = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_mapping`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setMappingData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const addMapping = (payload) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_mapping`,
        payload
      )
      .then((result) => {
        listMapping();
        handleCancel()
      })
      .catch((error) => { });
  }

  const editMapping = (payload) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_mapping`,
        payload
      )
      .then((result) => {
        listMapping();
        handleCancel();
      })
      .catch((error) => { });
  }

  const deleteMapping = (mappingId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_mapping/` + mappingId,
      )
      .then((result) => {
        listMapping();
      })
      .catch((error) => { });
  }

  const showModal = (record) => {
    let errors = { ...formData.errors };
    setModalVisible(true);
    setSelectedMapping(record);
    setFormData(record);
    setFormData({
      ...formData,
      ['customer_name']: record.customer_name,
      ['machine_model_name']: record.machine_model_name,
      ['DIN']: record.DIN,
      ['machine_number']: record.machine_number,
      ['customerId']: record.customer_id,
      ['machineModelId']:record.machine_model_id,
      ['deviceId']:record.device_id
    });
  }

  const handleDelete = (record) => {
    deleteMapping(record.mapping_id);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedMapping(null);
    cancelModal();
    setFormData({
      ...formData,
      ['customer_name']: '',
      ['machine_model_name']: '',
      ['DIN']: '',
      ['machine_number']: '',
      ['customerId']: '',
      ['machineModelId']:'',
      ['deviceId']:''
    });
  }

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target;
    let errors = { ...formData.errors };
    switch (name) {
      case 'machine_number':
        errors.machine_number = value.length < 3 ? 'Machine Number must be at least 3 characters long' : '';
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: value,
      errors: { ...errors }
    });
  };

  // const handleCustomerChange = (value) => {
  //   setCustomerId(option.key);
  // }

  const handleCustomerChange = (event) => {
    const { name, value } = event.target;
    const selectedValue = value;
    const selectedId = event.target.options[event.target.selectedIndex].getAttribute('data-id');
    switch (name) {
      case 'customer_name':
        setFormData({
          ...formData,
          [name]: selectedValue,
          customerId: selectedId
        });
        break;
      case 'machine_model_name':
        setFormData({
          ...formData,
          [name]: selectedValue,
          machineModelId: selectedId // Assuming you have a state property named selectedId
        });
        break;
      case 'DIN':
        setFormData({
          ...formData,
          [name]: selectedValue,
          deviceId: selectedId // Assuming you have a state property named selectedId
        });
        break;
      default:
        break;
    }
  };

  // const handleModelChange = (value, option) => {
  //   setMachineModelId(option.key)
  // }

  // const handleDeviceChange = (value, option) => {
  //   setDeviceId(option.key)
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    let valid = true;
    Object.values(formData.errors).forEach((error) => {
      if (error.length > 0) {
        valid = false;
      }
    });

    if (valid) {
      console.log('Form submitted:', formData);
      if (selectedMapping) {
        let payload = {
          "mapping_id": selectedMapping.mapping_id,
          "customer_id": formData.customerId || selectedMapping.customer_id,
          "machine_model_id": formData.machineModelId || selectedMapping.machine_model_id,
          "device_id": formData.deviceId || selectedMapping.device_id,
          "machine_number": formData.machine_number,
        }
        editMapping(payload);
        // console.log("payload>>>",payload);
      } else {
        let payload = {
          "customer_id": formData.customerId,
          "machine_model_id": formData.machineModelId,
          "device_id": formData.deviceId,
          "machine_number": formData.machine_number,
        }
        // console.log("payload>>>",payload);
        addMapping(payload);
      }
      // You can perform further actions here, like sending the form data to a server
    } else {
      console.error('Invalid form');
    }
  };

  const mappingColumns = [
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Machine Model Name',
      dataIndex: 'machine_model_name',
      key: 'machine_model_name',
    },
    {
      title: 'Device',
      dataIndex: 'DIN',
      key: 'DIN',
    },
    {
      title: 'Machine Number',
      dataIndex: 'machine_number',
      key: 'machine_number',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => showModal(record)} icon={<EditOutlined />} />
          <Button type="danger" onClick={() => handleDelete(record)} icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={mappingData} columns={mappingColumns} />

      <Modal
        title={selectedMapping ? 'Edit Mapping' : 'Add Mapping'}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="customer_name"> Customer:</label>
            <select
              id="customer_name"
              name="customer_name"
              value={formData.customer_name}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {customerData.map(option => (
                <option key={option.id} value={option.customer_name} data-id={option.id}>{option.customer_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="machine_model_name"> Machine Model:</label>
            <select
              id="machine_model_name"
              name="machine_model_name"
              value={formData.machine_model_name}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {modelData.map(option => (
                <option key={option.machine_model_id} value={option.machine_model_name} data-id={option.machine_model_id}>{option.machine_model_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="DIN"> Device:</label>
            <select
              id="DIN"
              name="DIN"
              value={formData.DIN}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {deviceData.map(option => (
                <option key={option.device_id} value={option.DIN} data-id={option.device_id}>{option.DIN}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="machine_number">Machine Number:</label>
            <input
              type="text"
              id="machine_number"
              name="machine_number"
              value={formData.machine_number}
              onChange={handleChange}
              required
            />
            {/* {formData.errors.machine_number.length > 0 && (
              <span className="error">{formData.errors.machine_number}</span>
            )} */}
          </div>
          <button type="submit">Submit</button>
        </form>
      </Modal>
    </div>

  );


};

export default MappingTable;