//Vehicle on Map
export const SOCKET_ALERT = 'Alert!!';
export const SOCKET_ERROR = 'Error in socket connection';
export const COUNT_TOTALVEHICLES = 'Total Vehicles';
export const COUNT_RSAVEHICLES = 'RSA Vehicles';
export const COUNT_CUSTOMERVEHICLES = 'Customer Vehicles';
export const COUNT_WOMENVEHICLES = 'Women Vehicles';
export const NOT_FOUND = 'Vehicle not found!!';

// export const SOCKET_ERROR = 'HasAlert or HasRSA';
// export const SOCKET_ERROR = 'RSA Request';
// export const SOCKET_ERROR = 'Parked';
// export const SOCKET_ERROR = 'Female Owne';
// export const SOCKET_ERROR = 'Default';
// export const SOCKET_ERROR = 'Error in socket connection';



