import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import DashboardComponent from "./components/Dashboard/dashboard";
import VehicleDetails from "./components/VehicleDetails/vehicleDetails";
import VehiclesOnMap from "./components/VehiclesOnMap/vehiclesOnMap";
import VehiclesOnmapHbase from "./components/VehiclesOnMap/vehicleOnMapHbase";
import AlertView from "./components/AlertView/alertView";
import LayoutComponent from "./components/Layout/layout";
import MasterComponent from "./components/Masters/masterTab";
// import Report from "./components/VehicleDetailsReport/Report";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderPage = () => {
    return (
      <Router>
        <LayoutComponent title="Dashboard">
          <div>
            {/* <Route
              exact
              path="/dashboard"
              name="Dashboard"
              render={(props) => <DashboardComponent title="Dashboard" />}
            /> */}
            <Route
              exact
              path="/vehicle-details/:id"
              name="Vehicle Details"
              render={(props) => <VehicleDetails title="Vehicle Details" />}
            />
            <Route
              exact
              path="/vehicle-details"
              name="Vehicle Details"
              render={(props) => <VehicleDetails title="Vehicle Details" />}
            />
            {/* <Route exact path="/article-detail/:text" name="news" render={props => <ArticleDetail {...props} />} /> */}

            <Route
              exact
              path="/vehicle-map/:id"
              name="Vehicles On Map"
              render={(props) => (
                <VehiclesOnMap title="Vehicles On Map" {...props} />
              )}
            />
            <Route
              exact
              path="/vehicle-map"
              name="Vehicles On Map"
              render={(props) => (
                <VehiclesOnMap title="Vehicles On Map" {...props} />
              )}
            />
            <Route
              exact
              path="/vehicle-map-hbase"
              name="Vehicles On Map(Hbase)"
              render={(props) => (
                <VehiclesOnmapHbase title="Vehicles On Map(Hbase)" {...props} />
              )}
            />
            <Route
              exact
              path="/alert-view"
              name="Alert View"
              render={(props) => <AlertView title="Alert View" {...props} />}
            />
            <Route
              exact
              path="/masters"
              name="Vehicle Master"
              render={(props) => <MasterComponent title="Master" {...props} />}
            />
          </div>
        </LayoutComponent>
      </Router>
    );
  };

  componentDidMount() {}

  render() {
    return this.renderPage();
  }
}

export default App;
