import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";


const CustomerTable = ({ data, cancelModal }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerData, setCustomerData] = useState([]);

    const [formData, setFormData] = useState({
        customer_name: '',
        mobile_number: '',
        errors: {
            customer_name: '',
            mobile_number: ''
        }
    });

    useEffect(() => {
        listCustomer();
        setModalVisible(data);
    }, [data]);

    const listCustomer = () => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_customers`,
            )
            .then((result) => {
                if (Array.isArray(result.data.data) && result.data.data.length) {
                    setCustomerData(result.data.data);
                }
            })
            .catch((error) => { });
    }

    const deleteCustomer = (customerId) => {
        axios
            .delete(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_customer/` + customerId,
            )
            .then((result) => {
                listCustomer();
            })
            .catch((error) => { });
    }

    const addCustomer = (payload) => {
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_customer`,
                payload
            )
            .then((result) => {
                listCustomer();
                handleCancel();
            })
            .catch((error) => { });
    }

    const editCustomer = (payload) => {
        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_customer`,
                payload
            )
            .then((result) => {
                listCustomer();
                handleCancel();
            })
            .catch((error) => { });
    }

    const showModal = (record) => {
        let errors = { ...formData.errors };
        setModalVisible(true);
        setSelectedCustomer(record);
        setFormData(record);
        setFormData({
            ...formData,
            ['customer_name']: record.customer_name,
            ['mobile_number']: record.mobile_number
        });
    }

    const handleDelete = (record) => {
        deleteCustomer(record.id);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setSelectedCustomer(null);
        cancelModal();
        setFormData({
            ...formData,
            ['customer_name']: '',
            ['mobile_number']: '',
        });
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        let errors = { ...formData.errors };

        switch (name) {
            case 'customer_name':
                errors.customer_name = value.length < 3 ? 'Customer name must be at least 3 characters long' : '';
                break;
            case 'mobile_number':
                errors.mobile_number = /^\d{10}$/.test(value) ? '' : 'Mobile  number must be 10 digits';
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            [name]: value,
            errors: { ...errors }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        let valid = true;
        Object.values(formData.errors).forEach((error) => {
            if (error.length > 0) {
                valid = false;
            }
        });

        if (valid) {
            console.log('Form submitted:', formData);
            if (selectedCustomer) {
                let payload = {
                    "customerName": formData.customer_name,
                    "customerId": selectedCustomer.id,
                    "mobileNumber": formData.mobile_number
                }
                editCustomer(payload);
            } else {
                let payload = {
                    "customerName": formData.customer_name,
                    "mobileNumber": formData.mobile_number
                }
                addCustomer(payload);
            }
            // You can perform further actions here, like sending the form data to a server
        } else {
            console.error('Invalid form');
        }
    };

    const customerColumns = [
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary"
                        onClick={() => showModal(record)}
                        icon={<EditOutlined />} />
                    <Button type="danger"
                        onClick={() => handleDelete(record)}
                        icon={<DeleteOutlined />} />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Table dataSource={customerData} columns={customerColumns} />

            <Modal
                title={selectedCustomer ? 'Edit Customer' : 'Add Customer'}
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="customer_name"> Customer Name:</label>
                        <input
                            type="text"
                            id="customer_name"
                            name="customer_name"
                            value={formData.customer_name}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.customer_name.length > 0 && (
                            <span className="error">{formData.errors.customer_name}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile_number">Mobile Number:</label>
                        <input
                            type="text"
                            id="mobile_number"
                            name="mobile_number"
                            value={formData.mobile_number}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.mobile_number.length > 0 && (
                            <span className="error">{formData.errors.mobile_number}</span>
                        )}
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </Modal>
        </div>

    );


};

export default CustomerTable;