import React, { Component } from "react";
import socketIOClient from "socket.io-client";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Row, Spin } from "antd";
import "./vehicle.css";
import { Select, Input } from "antd";
import axios from "axios";
import Map from "./Map";
import {
  SOCKET_ALERT,
  SOCKET_ERROR,
  NOT_FOUND,
} from "../../constants/constants";


let socket;
const { Search } = Input;

const alertArray = [
  {
    label: "Select Alert",
    value: "",
  }, {
    value: "hasAlerts",
    label: 'hasAlerts'
  }, {
    id: "noAlerts",
    value: 'noAlerts'
  }
];

class VehiclesOnMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceCentres: [],
      totalVehicles: 0,
      deviceWithAlertCount: 0,
      cityArray: [],
      modelArray: [],
      customerArray: [],
      alerFlag: false,
      searchInputVal: "",
      lat: "",
      long: "",
      initialCenter: { lat: 12.9716, lng: 77.5946 },
      initialZoom: 10,
      RSA: ["progress", "done"],
      ALERT: ["alerting here", "data not available"],
      vehicles: [],
      zoomVal: 10, //initial zoom
      zoomCoord: { lat: 12.9716, lng: 77.5946 }, //bangalore
      cityFilterVal: "",
      modelFilterVal: "",
      customerFilterVal: "",
      alertFilterVal: "",
      citySelected: "",
      modelSelected: "",
      customerSelected: "",
      alertSelected: ""
    };
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    if (this.props.match.path !== "/vehicle-map") {
      var link = document.location.href.split("/");
      let latlong = link[4].split(";");
      let lat = latlong[0].split(":");
      let long = latlong[1].split(":");
      let val = {
        lat: parseFloat(lat[1]),
        lng: parseFloat(long[1]),
      };
      if (link[4]) {
        this.setState({
          alertFlag: true,
          lat: lat[1],
          long: long[1],
        });
        this.setState({ zoomVal: 10, zoomCoord: val });
      } else {
        this.setState({ alertFlag: false });
      }
    }

    socket = socketIOClient(
      `${process.env.REACT_APP_BACKEND_URL}/getOngoingVehicles`
    );
    socket.on("reconnecting", () => {
      this.setState({ loader: false });
    });
    socket.on("ongoing", (data) => {
      console.log("hey", data)
      if (data.status === "FAILURE") {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{SOCKET_ALERT}</h1>
                <p>{SOCKET_ERROR}</p>
              </div>
            );
          },
        });
      } else {
        this.setState({
          vehicleListFromSocket: data.vehicleList,
        });
        this.filterVehicleList();
      }
    });
    this.getDropdownValues();
  }

  setZoomAndCoord = (zoomVal, zoomCoord) => {
    this.setState({
      zoomVal,
      zoomCoord,
    });
  };

  filterVehicleList = () => {
    let deviceWithAlertCount = 0;
    console.log("hey>>>", this.state.vehicleListFromSocket)
    let vehicles = this.state.vehicleListFromSocket

      .filter((vehicle) => {

        let keep = true;
        if (this.state.cityFilterVal) {
          keep = keep && vehicle.city === this.state.cityFilterVal;
        }
        if (this.state.modelFilterVal) {
          keep = keep && vehicle.model === this.state.modelFilterVal;
        }
        if (this.state.customerFilterVal) {
          keep = keep && vehicle.customername === this.state.customerFilterVal;
        }
        if (this.state.alertFilterVal) {
          keep = keep && vehicle.category === this.state.alertFilterVal;
        }
        if (keep) {
          if (vehicle.category === "hasAlerts") {
            deviceWithAlertCount++;
          }
        }
        return keep;
      })
      .map((vehicle) => ({
        type: "Feature",
        properties: {
          cluster: false,
          id: vehicle.id,
          city: vehicle.city,
          category: vehicle.category,
          customerType: vehicle.customerType,
          CustomerGender: vehicle.CustomerGender,
          showInfo: false,
          deviceId: vehicle.deviceId,
          customerName: vehicle.customername,
          // vehicleNumber: vehicle.vehicle_number,
          make: vehicle.make,
          model: vehicle.model,
          mobileNumber: vehicle.MobileNumber,
          status: vehicle.status,

        },
        geometry: {
          type: "Point",
          coordinates: [parseFloat(vehicle.lng), parseFloat(vehicle.lat)],
        },

      }));
    this.setState({
      deviceWithAlertCount,
      vehicles,
      totalVehicles: vehicles.length,
      loader: false,
    });
  };

  handlereload = async () => {
    await this.setState({
      // cityArray: [],
      // modelArray: [],
      // customerArray: [],
      cityFilterVal: "",
      modelFilterVal: "",
      customerFilterVal: "",
      alertFilterVal: "",
      citySelected: "",
      modelSelected: "",
      customerSelected: "",
      alertSelected: ""
    });
    await this.filterVehicleList();
    await this.setState({
      zoomVal: 8,
      zoomCoord: this.state.initialCenter,
    });
  };

  getDropdownValues() {
    // dropdown api
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/fetch_Filter`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        let cityArr = data.data.Cities.map((each) => ({

          label: each.city_name,
          value: JSON.stringify(each),
        }));
        cityArr = [
          {
            label: "Select City",
            value: "",
          },
        ].concat(cityArr);


        let modelArr = data.data.model.map((each) => ({
          label: each,
          value: each,
        }));
        modelArr = [
          {
            label: "Select Model",
            value: "",
          },
        ].concat(modelArr);

        let customerArr = data.data.customer.map((each) => ({
          label: each,
          value: each,
        }));
        customerArr = [
          {
            label: "Select Customer",
            value: "",
          },
        ].concat(customerArr);

        this.setState({
          // typeArray: typeArr,
          cityArray: cityArr,
          modelArray: modelArr,
          customerArray: customerArr
          // alertArray: alertArr,
        });
      })
      .catch((error) => { });
  }

  handleChangeType = async (val, type) => {
    switch (type) {
      case "city":
        const oldVal = JSON.parse(val);
        const zoomVal = oldVal?.latitude && oldVal?.longitude ? this.state.initialZoom : 8;
        const zoomCoord = oldVal?.latitude && oldVal?.longitude ? { lat: parseFloat(oldVal.latitude), lng: parseFloat(oldVal.longitude) } : this.state.initialCenter;
        await this.setState({
          cityFilterVal: oldVal ? oldVal.city_name : "",
          citySelected: val,
          zoomVal: zoomVal,
          zoomCoord: zoomCoord
        });
        break;
      case "model":
        await this.setState({
          modelFilterVal: val,
          modelSelected: val,
        });
        break;
      case "customer":
        await this.setState({
          customerFilterVal: val,
          customerSelected: val,
        });
        break;
      case "alert":
        console.log("hjnkml", val)
        await this.setState({
          alertFilterVal: val,
          alertSelected: val,
        });
        break;
      default:
        // Handle other cases here
        break;

    }
    await this.setState({
      searchInputVal: "",
    });

    await this.filterVehicleList();
  };

  handleSearchValChange = async (e) => {
    await this.setState({
      searchInputVal: e.target.value,
    });
  };

  //search vehicles
  handleSearchInputChange = async (value) => {
    if (!value) {
      return;
    }
    let val = value.toLowerCase().trim();

    let obj = this.state.vehicles.find(
      (element) =>
        element.properties.deviceId &&
        element.properties.deviceId.toLowerCase().includes(val)
    );
    if (!obj) {
      return confirmAlert({
        customUI: () => {
          return (
            <div className="custom-ui">
              <h1>{NOT_FOUND}</h1>
            </div>
          );
        },
      });
    }

    await this.setState({
      zoomVal: 80,
      zoomCoord: {
        lat: parseFloat(obj.geometry.coordinates[1]),
        lng: parseFloat(obj.geometry.coordinates[0]),
      },
    });
  };

  render() {
    const { modelArray, cityArray, loader, customerArray } = this.state;
    return (
      <div>
        {/* <Content> */}
        <div className="left-sec">
          <Search
            placeholder="Search Device"
            type="text"
            className="search-map"
            autoComplete="off"
            value={this.state.searchInputVal}
            onChange={(e) => this.handleSearchValChange(e)}
            onSearch={(value) => this.handleSearchInputChange(value)}
          />
          <div className="clearfix"></div>

          <div className="card-sec">
            <div className="site-card-wrapper">
              <Row gutter={16}>
                <Col span={20}>
                  <Card bordered={false}>
                    <div className="all">
                      <div className="">Total devices</div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="all">
                      <div className="left">
                        <p className="count">{this.state.totalVehicles}</p>
                      </div>
                    </div>
                    <div className="clearfix"></div>{" "}
                    <div className="all">
                      <div className=""> Devices with alert</div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="all">
                      <div className="left">
                        <p className="count">{this.state.deviceWithAlertCount}</p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>

          <div className="dropdown-sec">
            <Select
              style={{ width: "80%" }}
              // mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value &&
                JSON.parse(option.value) &&
                JSON.parse(option.value).name &&
                JSON.parse(option.value)
                  .name.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select City"
              value={this.state.citySelected}
              options={cityArray}
              onChange={(e) => this.handleChangeType(e, "city")}
            ></Select>
            <img
                alt="refresh"
                onClick={this.handlereload}
                className="refreshBtn"
                height="25px"
                src={require("../../assets/image/refresh.png")}
              />
          </div>


          <div className="dropdown-sec">
            <Select
              style={{ width: "80%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue="Select Make"
              value={this.state.modelSelected}
              options={modelArray}
              onChange={(e) => this.handleChangeType(e, "model")}
            ></Select>
          </div>

          <div className="dropdown-sec">
            <Select
              style={{ width: "80%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue="Select Make"
              value={this.state.customerSelected}
              options={customerArray}
              onChange={(e) => this.handleChangeType(e, "customer")}
            ></Select>
          </div>
          <div className="dropdown-sec">
            <Select
              style={{ width: "80%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue="Select Alert"
              value={this.state.alertSelected}
              options={alertArray}
              onChange={(e) => this.handleChangeType(e, "alert")}
            ></Select>
          </div>
        </div>
        {loader ? (
          <p className="loaderItem">
            {" "}
            <Spin size="large" />
          </p>
        ) : (
          <div className="right-section">
            <div className="map-sec">
              <Map
                vehicles={this.state.vehicles}
                zoomVal={this.state.zoomVal}
                zoomCoord={this.state.zoomCoord}
                setZoomAndCoord={this.setZoomAndCoord}
              />
            </div>

            <div className="search-section">
              <div className="icon-sec">


                <img src="/green_icon1.png" className="vehicle-icon" alt="no alert"></img>
                <span className="icon-name">No Alert</span>

                <img src="/red_icon1.png" className="vehicle-icon" alt="has alert"></img>
                <span className="icon-name">Has Alert</span>

                <img src="/grey_icon1.png" className="vehicle-icon" alt="parked"></img>
                <span className="icon-name">Parked</span>


              </div>
            </div>
          </div>
        )}

        {/* </Content> */}
      </div>
    );
  }
}
export default VehiclesOnMap;
