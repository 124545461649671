import React, { Component } from "react";
import { Layout, Divider, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import logo1 from "../../../assets/image/niveus_logo.png";
import logo_mobile from "../../../assets/image/logomobile.png";
const { Header } = Layout;

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapActive: false,
      alertActive: false,
      detailsActive: false,
      dashboardActive: false,
      reportActive: false,
      masterActive: false,
      hbaseActive: false,
    };
  }

  componentDidMount() {
    var tblId = document.location.href.split("/");
    if (tblId[3] == "vehicle-map") {
      this.setState({
        mapActive: true,
      });
    } else if (tblId[3] == "vehicle-map-hbase") {
      this.setState({
        hbaseActive: true
      })

    } else if (tblId[3] == "alert-view") {
      this.setState({
        alertActive: true,
      });
    } else if (tblId[3] == "dashboard") {
      this.setState({
        dashboardActive: true,
      });
    } else if (tblId[3] == "vehicle-details") {
      this.setState({
        detailsActive: true,
      });
    } else if (tblId[3] == "vehicle-report") {
      this.setState({
        reportActive: true,
      });
    } else {
      return;
    }
  }

  async vehicleDetails() {
    await this.setState({
      detailsActive: true,
      alertActive: false,
      mapActive: false,
      dashboardActive: false,
      masterActive: false,
      reportActive: false,
    });
    this.props.history.push("/vehicle-details");
  }

  async vehiclesOnmap() {
    await this.setState({
      mapActive: true,
      hbaseActive: false,
      alertActive: false,
      detailsActive: false,
      dashboardActive: false,
      masterActive: false,
      reportActive: false,
    });
    this.props.history.push("/vehicle-map");
  }

  async vehiclesOnmapHbase() {
    await this.setState({
      mapActive: false,
      hbaseActive: true,
      alertActive: false,
      detailsActive: false,
      dashboardActive: false,
      masterActive: false,
      reportActive: false,
    });
    this.props.history.push("/vehicle-map-hbase");
  }

  async alertView() {
    await this.setState({
      mapActive: false,
      hbaseActive: false,
      alertActive: true,
      alertView: false,
      detailsActive: false,
      dashboardActive: false,
      masterActive: false,
      reportActive: false,
    });
    this.props.history.push("/alert-view");
  }
  
  async Report() {
    await this.setState({
      mapActive: false,
      hbaseActive: false,
      alertActive: false,
      detailsActive: false,
      dashboardActive: false,
      masterActive: false,
      reportActive: true,
    });
    this.props.history.push("/report");
  }



  async master() {
    await this.setState({
      mapActive: false,
      hbaseActive: false,
      alertActive: false,
      detailsActive: false,
      masterActive: true,
      dashboardActive: false,
    });
    this.props.history.push("/masters");
  }

  render() {
    return (
      <Header id="headerLayout" className="site-layout-sub-header-background">
        <img alt="" id="logoDesktop" className="logo-desktop" src={logo1} />
        <img alt="" id="logoMobile" className="logo-mobile" src={logo_mobile} />
        <Divider type="vertical" className="divider"></Divider>
        <div id="headerRightSec" className="search-bell-usr">
          <Button
            className={
              this.state.masterActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.master()}
          >
            Masters
          </Button>
          <Button
            className={
              this.state.mapActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.vehiclesOnmap()}
          >
            Devices On Map
          </Button>
          <Button
            className={
              this.state.hbaseActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.vehiclesOnmapHbase()}
          >
            Devices On Map(Hbase)
          </Button>
          <Button
            className={
              this.state.detailsActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.vehicleDetails()}
          >
            Telematics
          </Button>
          <Button
            className={
              this.state.alertActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.alertView()}
          >
            Alerts
          </Button>

          {/* <Button
            className={
              this.state.dashboardActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.dashboard()}
          >
            Dashboard
          </Button>
          <Button
            className={
              this.state.reportActive == true
                ? "header-options-active"
                : "header-options"
            }
            onClick={() => this.Report()}
          >
            Vehicle Details Report
          </Button> */}
        </div>
      </Header>
    );
  }
}
export default withRouter(HeaderComponent);
