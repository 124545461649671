import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";


const PidTable = ({ data, cancelModal }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPid, setSelectedPid] = useState(null);
    const [pidData, setPidData] = useState([]);

    const [formData, setFormData] = useState({
        pid_standard_name: '',
        max_value: '',
        min_value: '',
        errors: {
            pid_standard_name: '',
            max_value: '',
            min_value: ''
        }
    });

    useEffect(() => {
        listPid();
        setModalVisible(data);
    }, [data]);

    const listPid = () => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_pids`,
            )
            .then((result) => {
                if (Array.isArray(result.data.data) && result.data.data.length) {
                    setPidData(result.data.data);
                }
            })
            .catch((error) => { });
    }

    const addPid = (payload) => {
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_pid`,
                payload
            )
            .then((result) => {
                listPid();
                handleCancel();
            })
            .catch((error) => { });
    }

    const editPid = (payload) => {
        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_pid`,
                payload
            )
            .then((result) => {
                listPid();
                handleCancel();
            })
            .catch((error) => { });
    }

    const deletePid = (pid) => {
        axios
            .delete(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_pid/` + pid,
            )
            .then((result) => {
                listPid();
            })
            .catch((error) => { });
    }

    const showModal = (record) => {
        let errors = { ...formData.errors };
        setModalVisible(true);
        setSelectedPid(record);
        setFormData(record);
        setFormData({
            ...formData,
            ['pid_standard_name']: record.pid_standard_name,
            ['max_value']: record.max_value,
            ['min_value']: record.min_value
        });
    }

    const handleDelete = (record) => {
        deletePid(record.pid);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setSelectedPid(null);
        cancelModal();
        setFormData({
            ...formData,
            ['pid_standard_name']: '',
            ['min_value']: '',
            ['max_value']: '',
        });
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        let errors = { ...formData.errors };

        switch (name) {
            case 'pid_standard_name':
                errors.pid_standard_name = value.length < 3 ? 'Customer name must be at least 3 characters long' : '';
                break;
            case 'min_value':
                // errors.min_value = /^\d{10}$/.test(value) ? '' : 'Mobile  number must be 10 digits';
                break;
            case 'max_value':
                // errors.min_value = /^\d{10}$/.test(value) ? '' : 'Mobile  number must be 10 digits';
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            [name]: value,
            errors: { ...errors }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        let valid = true;
        Object.values(formData.errors).forEach((error) => {
            if (error.length > 0) {
                valid = false;
            }
        });

        if (valid) {
            console.log('Form submitted:', formData);
            if (selectedPid) {
                let payload = {
                    "pid": selectedPid.pid,
                    "pidStandardName": formData.pid_standard_name,
                    "minValue": formData.min_value || null,
                    "maxValue": formData.max_value || null
                  }
                editPid(payload);
            } else {
                let payload = {
                    "pidStandardName": formData.pid_standard_name,
                    "minValue": formData.min_value || null,
                    "maxValue": formData.max_value || null
                  }
                addPid(payload);
            }
            // You can perform further actions here, like sending the form data to a server
        } else {
            console.error('Invalid form');
        }
    };

    const pidColumns = [
        {
            title: 'Pid Standard Name',
            dataIndex: 'pid_standard_name',
            key: 'pid_standard_name',
        },
        {
            title: 'Min Value',
            dataIndex: 'min_value',
            key: 'min_value',
        },
        {
            title: 'Max Value',
            dataIndex: 'max_value',
            key: 'max_value',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => showModal(record)} icon={<EditOutlined />} />
                    <Button type="danger" onClick={() => handleDelete(record)} icon={<DeleteOutlined />} />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Table dataSource={pidData} columns={pidColumns} />

            <Modal
                title={selectedPid ? 'Edit Pid' : 'Add Pid'}
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="pid_standard_name"> Pid Standard Name:</label>
                        <input
                            type="text"
                            id="pid_standard_name"
                            name="pid_standard_name"
                            value={formData.pid_standard_name}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.pid_standard_name.length > 0 && (
                            <span className="error">{formData.errors.pid_standard_name}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="min_value">Min Value:</label>
                        <input
                            type="text"
                            id="min_value"
                            name="min_value"
                            value={formData.min_value}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.min_value.length > 0 && (
                            <span className="error">{formData.errors.min_value}</span>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="max_value">Max Value:</label>
                        <input
                            type="text"
                            id="max_value"
                            name="max_value"
                            value={formData.max_value}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.max_value.length > 0 && (
                            <span className="error">{formData.errors.max_value}</span>
                        )}
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </Modal>
        </div>

    );


};

export default PidTable;