import React, { useState } from "react";

import { Button, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import "./masters.css";
import CustomerTable from './customerTable';
import ModelTable from "./modelTable";
import DeviceTable from "./deviceTable";
import PidTable from "./pidTable";
import RuleTable from "./ruleTable";
import MappingTable from "./mappingTable";

const { TabPane } = Tabs;


const MasterTab = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modelVisible, setModelVisible] = useState(false);
  const [deviceVisible,setDeviceVisible] = useState(false);
  const [pidVisible,setPidVisible] = useState(false);
  const [ruleVisible, setRuleVisible] = useState(false);
  const [mappingVisible, setMappingVisible] = useState(false);
  const [tabType, setTabType] = useState('1');

  const showModal = () => {
    switch (tabType) {
      case '1':
        setModalVisible(true);
        break;
      case '2':
        setModelVisible(true);
        break;
      case '3':
        setDeviceVisible(true);
        break;
      case '4':
        setPidVisible(true);
        break;
      case '5':
        setRuleVisible(true);
        break;
      case '6':
        setMappingVisible(true);
        break;
      default:
        break;
    }
  }

  const cancelModal = () => {
    // switch (tabType) {
    //   case '1':
        setModalVisible(false)
        // break;
      // case '2':
        setModelVisible(false)
        setDeviceVisible(false);
        setPidVisible(false);
        setRuleVisible(false);
        setMappingVisible(false);
    //     break;
    // }
  };

  const handleTabChange = (key) => {
    setTabType(key);
  };

  return (
    <div>
      <Button type="primary" icon={<PlusOutlined />}
        onClick={() => showModal()}
        style={{ float: 'right', margin: '20px' }}>
        Add
      </Button>
      <Tabs onChange={handleTabChange}>
        <TabPane tab="Customer" key="1">
          <CustomerTable data={modalVisible} cancelModal={cancelModal} />
        </TabPane>
        <TabPane tab="Model" key="2">
          <ModelTable data={modelVisible} cancelModal={cancelModal}></ModelTable>
        </TabPane>
        <TabPane tab="Device" key="3">
          <DeviceTable data={deviceVisible} cancelModal={cancelModal}></DeviceTable>
        </TabPane>
        <TabPane tab="Pid" key="4">
          <PidTable data={pidVisible} cancelModal={cancelModal}></PidTable>
        </TabPane>
        <TabPane tab="Rule" key="5">
          <RuleTable data={ruleVisible} cancelModal={cancelModal}></RuleTable>
        </TabPane>
        <TabPane tab="Mapping" key="6">
          <MappingTable data={mappingVisible} cancelModal={cancelModal}></MappingTable>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MasterTab;