import React, { Component } from 'react';
import { Layout, Menuss } from 'antd';
import HeaderComponent from '../common/Header/header';
import '../../styles/layout.css';

const { Header, Footer, Sider, Content } = Layout;

class LayoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Layout className='layout-height'>
        <HeaderComponent />
        <Layout>
          <Content id='contentDiv' className='content-div'>
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default LayoutComponent;
