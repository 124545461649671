import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";

const conditionData = [
  {
    "id": 1,
    "condition": ">"
  },
  {
    "id": 2,
    "condition": "<"
  },
  {
    "id": 3,
    "condition": "="
  }
];

const criticalityData = [
  {
    "id": 1,
    "criticality": "High"
  },
  {
    "id": 2,
    "criticality": "Medium"
  },
  {
    "id": 3,
    "criticality": "Low"
  }
];

const RuleTable = ({ data, cancelModal }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [ruleData, setRuleData] = useState([]);
  const [pidData, setPidData] = useState([]);

  const [formData, setFormData] = useState({
    rule_description: '',
    pid_standard_name_1: '',
    pid_condition_1: '',
    pid_value_1: '',
    pid_standard_name_2: '',
    pid_condition_2: '',
    pid_value_2: '',
    alert_message: '',
    criticality: '',
    errors: {
      rule_description: '',
      pid_standard_name_1: '',
      pid_condition_1: '',
      pid_value_1: '',
      pid_standard_name_2: '',
      pid_condition_2: '',
      pid_value_2: '',
      alert_message: '',
      criticality: ''
    }
  });

  useEffect(() => {
    listRule();
    listPid();
    setModalVisible(data);
  }, [data]);

  const listPid = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_pids`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setPidData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const listRule = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_rules`,
      )
      .then((result) => {
        if (Array.isArray(result.data.data) && result.data.data.length) {
          setRuleData(result.data.data);
        }
      })
      .catch((error) => { });
  }

  const addRule = (payload) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_rule`,
        payload
      )
      .then((result) => {
        listRule();
        handleCancel();
      })
      .catch((error) => { });
  }

  const editRule = (payload) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_rule`,
        payload
      )
      .then((result) => {
        listRule();
        handleCancel();
      })
      .catch((error) => { });
  }

  const deleteRule = (ruleId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_rule/` + ruleId,
      )
      .then((result) => {
        listRule();
      })
      .catch((error) => { });
  }

  const showModal = (record) => {
    let errors = { ...formData.errors };
    setModalVisible(true);
    setSelectedRule(record);
    setFormData(record);
    setFormData({
      ...formData,
      ['rule_description']: record.rule_description,
      ['pid_standard_name_1']: record.pid_standard_name_1,
      ['pid_condition_1']: record.pid_condition_1,
      ['pid_value_1']: record.pid_value_1,
      ['pid_standard_name_2']: record.pid_standard_name_2,
      ['pid_condition_2']: record.pid_condition_2,
      ['pid_value_2']: record.pid_value_2,
      ['alert_message']:record.alert_message,
      ['criticality']: record.criticality,
    });
  }

  const handleDelete = (record) => {
    deleteRule(record.rule_id);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedRule(null);
    cancelModal();
    setFormData({
      ...formData,
      ['customer_name']: '',
      ['mobile_number']: '',
      ['rule_description']: '',
      ['pid_standard_name_1']: '',
      ['pid_condition_1']: '',
      ['pid_value_1']: '',
      ['pid_standard_name_2']: '',
      ['pid_condition_2']: '',
      ['pid_value_2']: '',
      ['alert_message']:'',
      ['criticality']: '',
    });
  }

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target;
    let errors = { ...formData.errors };

    switch (name) {
      case 'rule_description':
        errors.rule_description = value.length < 3 ? 'Rule description must be at least 3 characters long' : '';
        break;
      case 'alert_message':
        errors.alert_message = value.length < 3 ? 'Rule description must be at least 3 characters long' : '';
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: value,
      errors: { ...errors }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    let valid = true;
    Object.values(formData.errors).forEach((error) => {
      if (error.length > 0) {
        valid = false;
      }
    });

    if (valid) {
      console.log('Form submitted:', formData);
      if (selectedRule) {
        let payload = {
          "ruleId": selectedRule.rule_id,
          "ruleDescription": formData.rule_description,
          "pidStandardName1": formData.pid_standard_name_1,
          "pidCondition1": formData.pid_condition_1,
          "pidValue1": formData.pid_value_1,
          "pidStandardName2": formData.pid_standard_name_2,
          "pidCondition2": formData.pid_condition_2,
          "pidValue2": formData.pid_value_2,
          "alertMessage": formData.alert_message,
          "criticality": formData.criticality
        }
        // console.log("payload>>>>",payload);
        editRule(payload);
      } else {
        let payload = {
          "ruleDescription": formData.rule_description,
          "pidStandardName1": formData.pid_standard_name_1,
          "pidCondition1": formData.pid_condition_1,
          "pidValue1": formData.pid_value_1,
          "pidStandardName2": formData.pid_standard_name_2,
          "pidCondition2": formData.pid_condition_2,
          "pidValue2": formData.pid_value_2,
          "alertMessage": formData.alert_message,
          "criticality": formData.criticality
        }
        // console.log("payload>>>>",payload);
        addRule(payload);
      }
      // You can perform further actions here, like sending the form data to a server
    } else {
      console.error('Invalid form');
    }
  };

  const handleCustomerChange = (event) => {
    const { name, value } = event.target;
    const selectedValue = value;
    setFormData({
      ...formData,
      [name]: selectedValue,
    });
    // switch (name) {
    //   case 'criticality':
    //     setFormData({
    //       ...formData,
    //       [name]: selectedValue,
    //       customerId: selectedId
    //     });
    //     break;
    //   case 'machine_model_name':
    //     setFormData({
    //       ...formData,
    //       [name]: selectedValue,
    //       machineModelId: selectedId // Assuming you have a state property named selectedId
    //     });
    //     break;
    //   case 'DIN':
    //     setFormData({
    //       ...formData,
    //       [name]: selectedValue,
    //       deviceId: selectedId // Assuming you have a state property named selectedId
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };

  const ruleColumns = [
    {
      title: 'Rule Description',
      dataIndex: 'rule_description',
      key: 'rule_description',
    },
    {
      title: 'Alert Message',
      dataIndex: 'alert_message',
      key: 'alert_message',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      key: 'criticality',
    },
    {
      title: 'Pid Standard Name 1',
      dataIndex: 'pid_standard_name_1',
      key: 'pid_standard_name_1',
    },
    {
      title: 'Pid Condition 1',
      dataIndex: 'pid_condition_1',
      key: 'pid_condition_1',
    },
    {
      title: 'Pid Value 1',
      dataIndex: 'pid_value_1',
      key: 'pid_value_1',
    },
    {
      title: 'Pid Standard Name 2',
      dataIndex: 'pid_standard_name_2',
      key: 'pid_standard_name_2',
    },
    {
      title: 'Pid Condition 2',
      dataIndex: 'pid_condition_2',
      key: 'pid_condition_2',
    },
    {
      title: 'Pid Value 2',
      dataIndex: 'pid_value_2',
      key: 'pid_value_2',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => showModal( record)} icon={<EditOutlined />} />
          <Button type="danger" onClick={() => handleDelete(record)} icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={ruleData} columns={ruleColumns} />
      <Modal
        title={selectedRule ? 'Edit Rule' : 'Add Rule'}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="rule_description"> Rule Description:</label>
            <input
              type="text"
              id="rule_description"
              name="rule_description"
              value={formData.rule_description}
              onChange={handleChange}
              required
            />
            {formData.errors.rule_description.length > 0 && (
              <span className="error">{formData.errors.rule_description}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="alert_message">Alert Message:</label>
            <input
              type="text"
              id="alert_message"
              name="alert_message"
              value={formData.alert_message}
              onChange={handleChange}
              required
            />
            {formData.errors.alert_message.length > 0 && (
              <span className="error">{formData.errors.alert_message}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="criticality"> Criticality:</label>
            <select
              id="criticality"
              name="criticality"
              value={formData.criticality}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {criticalityData.map(option => (
                <option key={option.id} value={option.criticality} >{option.criticality}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pid_standard_name_1"> Pid Standard Name 1:</label>
            <select
              id="pid_standard_name_1"
              name="pid_standard_name_1"
              value={formData.pid_standard_name_1}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {pidData.map(option => (
                <option key={option.pid} value={option.pid_standard_name}>{option.pid_standard_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pid_condition_1"> Pid Condition 1:</label>
            <select
              id="pid_condition_1"
              name="pid_condition_1"
              value={formData.pid_condition_1}
              onChange={handleCustomerChange}
              required
            >
              <option value="">Select an option</option>
              {conditionData.map(option => (
                <option key={option.id} value={option.condition}>{option.condition}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pid_value_1">Pid Value 1:</label>
            <input
              type="text"
              id="pid_value_1"
              name="pid_value_1"
              value={formData.pid_value_1}
              onChange={handleChange}
              required
            />
            {formData.errors.pid_value_1.length > 0 && (
              <span className="error">{formData.errors.pid_value_1}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="And">And</label>
          </div>
          <div className="form-group">
            <label htmlFor="pid_standard_name_2"> Pid Standard Name 2:</label>
            <select
              id="pid_standard_name_2"
              name="pid_standard_name_2"
              value={formData.pid_standard_name_2}
              onChange={handleCustomerChange}
              // required
            >
              <option value="">Select an option</option>
              {pidData.map(option => (
                <option key={option.pid} value={option.pid_standard_name}>{option.pid_standard_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pid_condition_2"> Pid Condition 2:</label>
            <select
              id="pid_condition_2"
              name="pid_condition_2"
              value={formData.pid_condition_2}
              onChange={handleCustomerChange}
              // required
            >
              <option value="">Select an option</option>
              {conditionData.map(option => (
                <option key={option.id} value={option.condition}>{option.condition}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pid_value_2">Pid Value 2:</label>
            <input
              type="text"
              id="pid_value_2"
              name="pid_value_2"
              value={formData.pid_value_2}
              onChange={handleChange}
              // required
            />
            {formData.errors.pid_value_2.length > 0 && (
              <span className="error">{formData.errors.pid_value_2}</span>
            )}
          </div>
          <button type="submit">Submit</button>
        </form>
      </Modal>
    </div>
  );
};

export default RuleTable;