import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";


const ModelTable = ({ data, cancelModal }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [modelData, setModelData] = useState([]);

    const [formData, setFormData] = useState({
        machine_model_name: '',
        description: '',
        errors: {
            machine_model_name: '',
            description: ''
        }
    });

    useEffect(() => {
        listModel();
        setModalVisible(data);
    }, [data]);

    const listModel = () => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_models`,
            )
            .then((result) => {
                if (Array.isArray(result.data.data) && result.data.data.length) {
                    setModelData(result.data.data);
                }
            })
            .catch((error) => { });
    }

    const deleteModel = (modelId) => {
        axios
            .delete(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_model/` + modelId,
            )
            .then((result) => {
                listModel();
            })
            .catch((error) => { });
    }

    const addModel = (payload) => {
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_model`,
                payload
            )
            .then((result) => {
                listModel();
                handleCancel();
            })
            .catch((error) => { });
    }

    const editModel = (payload) => {
        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_model`,
                payload
            )
            .then((result) => {
                listModel();
                handleCancel();
            })
            .catch((error) => { });
    }

    const showModal = (record) => {
        let errors = { ...formData.errors };
        setModalVisible(true);
        setSelectedModel(record);
        setFormData(record);
        setFormData({
            ...formData,
            ['machine_model_name']: record.machine_model_name,
            ['description']: record.description
        });
    }

    const handleDelete = (record) => {
        deleteModel(record.machine_model_id);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setSelectedModel(null);
        cancelModal();
        setFormData({
            ...formData,
            ['machine_model_name']: '',
            ['description']: '',
        });
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        let errors = { ...formData.errors };

        switch (name) {
            case 'machine_model_name':
                errors.machine_model_name = value.length < 3 ? 'Model name must be at least 3 characters long' : '';
                break;
            case 'description':
                errors.description =value.length < 3 ? 'Description must be at least 3 characters long' : '';
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            [name]: value,
            errors: { ...errors }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        let valid = true;
        Object.values(formData.errors).forEach((error) => {
            if (error.length > 0) {
                valid = false;
            }
        });

        if (valid) {
            console.log('Form submitted:', formData);
            if (selectedModel) {
                let payload = {
                    "machine_model_name": formData.machine_model_name,
                    "id": selectedModel.machine_model_id,
                    "description": formData.description
                }
                // console.log("payload>>",payload);
                editModel(payload);
            } else {
                let payload = {
                    "machine_model_name": formData.machine_model_name,
                    "description": formData.description
                }
                // console.log("payload>>",payload);
                addModel(payload);
            }
            // You can perform further actions here, like sending the form data to a server
        } else {
            console.error('Invalid form');
        }
    };

    const modelColumns = [
        {
          title: 'Model Name',
          dataIndex: 'machine_model_name',
          key: 'machine_model_name',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button type="primary" onClick={() => showModal(record)} icon={<EditOutlined />} />
              <Button type="danger" onClick={() => handleDelete(record)} icon={<DeleteOutlined />} />
            </Space>
          ),
        },
      ];

    return (
        <div>
            <Table dataSource={modelData} columns={modelColumns} />

            <Modal
                title={selectedModel ? 'Edit Model' : 'Add Model'}
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="machine_model_name"> Model Name:</label>
                        <input
                            type="text"
                            id="machine_model_name"
                            name="machine_model_name"
                            value={formData.machine_model_name}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.machine_model_name.length > 0 && (
                            <span className="error">{formData.errors.machine_model_name}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.description.length > 0 && (
                            <span className="error">{formData.errors.description}</span>
                        )}
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </Modal>
        </div>

    );


};

export default ModelTable;