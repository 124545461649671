import React, { Component } from "react";
import {
  Table,
  Select,
  Input,
  Spin,
  message,
  //  SearchBar
} from "antd";
import { Link } from "react-router-dom";
import "./alertView.css";
import moment from "moment";
import socketIOClient from "socket.io-client";
import "simplebar/dist/simplebar.min.css";
import axios from "axios";
const { Search } = Input;
let socket;
let counter = 0;
let REG = /\b(?:minute|minutes|second|seconds)\b/gi;
class AlertView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handleChange: false,
      firstOccurance: true,
      data: [],
      count: 0,
      alertArray: [],
      dataSource: [],
      dataFromSocket: [],
      alertTimestamp: [],
      alertType: [],
      locationType: [],
      oemType: [],
      dataSourceCopy: [],
      loader: false,
      array: [false, false, false, false, false],
      selectValues: [false, false, false, false, false],
      header: [
        "Alert Type",
        "Alert Value",
        "Criticality",
        "Alert Description",
        "Model/Vehicle number ",
        "Customer Name/Mobile",
        "Alert time",
        "Location",
      ],
      type: "",
      value: "",
      quantity: null,
      searchVal: "",
      cityFilterVal: "",
      makeFilterVal: "",
      typeFilterVal: "",
      searchFilterVal: "",
    };

    const renderAlertIcon = (tags) => {
      let iconSrc = "/high.png"; // Default icon

      switch (tags) {
        case "Towing":
          iconSrc = "/Towing.png";
          break;
        case "Low Battery Voltage":
          iconSrc = "/Low battery.png";
          break;
        case "High Coolant Temperature":
          iconSrc = "/High Coolant temperature.png";
          break;
        case "Fatigue Driving Detected":
          iconSrc = "/fatigue.png";
          break;
        case "Device Plug Out":
          iconSrc = "/device_plugout.png";
          break;
        case "Geofence Breach":
          iconSrc = "/Geofence Breach.png";
          break;
        case "DTC Code Detected":
          iconSrc = "/OBD.png";
          break;
        default:
          break;
      }

      return (
        <div key={tags}>
          <img src={iconSrc} className="alert-icon"></img>
        </div>
      );
    };

    function getClassname(text) {
      const classNames = {
        'severe': 'CriticSevere',
        'high': 'CriticHigh',
        'medium': 'CriticMedium',
        'low': 'CriticLow'
      };

      const lowerText = text.toLowerCase();
      return classNames[lowerText] || '';
    }

    this.columns = [
      {
        title: "Alert Type",
        dataIndex: "ALERT_TYPE",
        fixed: "top",
        width: "9%",
        onCell: () => {
          return {
            style: {
              minWidth: 130,
              maxWidth: 130,
            },
          };
        },
        align: "center",
        render: (tags) => (
          <>
            {renderAlertIcon(tags)}
          </>
        ),
      },

      // {
      //   title: "Alert Value",
      //   dataIndex: "ALERT_VALUE",
      //   align: "center",
      //   width: "9%",
      //   onCell: () => {
      //     return {
      //       style: {
      //         minWidth: 130,
      //         maxWidth: 130,
      //       },
      //     };
      //   },
      // },
      {
        title: "Criticality",
        dataIndex: "CRITICALITY",
        align: "center",
        width: "8%",
        onCell: () => {
          return {
            style: {
              minWidth: 130,
              maxWidth: 130,
            },
          };
        },
        render(text) {
          return {
            children: (
              <div>
                {text && (
                  <div className={getClassname(text)}>
                    {text}
                  </div>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Alert Message",
        dataIndex: "DESCRIPTION",
        onCell: () => {
          return {
            style: {
              minWidth: 50,
              maxWidth: 50,
            },
          };
        },
        align: "center",

        // render(text) {
        //   return {
        //     children:
        //       text != null ? (
        //         <div>
        //           {text.length < 100 ? (
        //             <p class="test tooltip-top">{text}</p>
        //           ) : (
        //             <p class="test tooltip-top" data-tooltip={text}>
        //               {text.slice(0, 100).concat("...")}
        //             </p>
        //           )}
        //         </div>
        //       ) : null,
        //   };
        // },
      },
      {
        title: "Model/ Device number",
        dataIndex: "REG_NUMBER",
        align: "center",
        width: "12%",
        render(text) {
          return {
            children: (
              <div>
                <li>{text[1]}</li>
                <li>{text[0]}</li>
              </div>
            ),
          };
        },
        onCell: () => {
          return {
            style: {
              minWidth: 130,
              maxWidth: 130,
            },
          };
        },
      },
      {
        title: "Customer Name/ Mobile",
        dataIndex: "NUMBER",
        align: "center",
        width: "15%",
        render(text) {
          return {
            children: (
              <div>
                <li>{text[0]}</li>
                <li>{text[1]}</li>
              </div>
            ),
          };
        },
        onCell: () => {
          return {
            style: {
              minWidth: 130,
              maxWidth: 130,
            },
          };
        },
      },
      {
        title: "Alert time",
        dataIndex: "ALERT_CREATE_TIME",
        align: "center",
        width: "13%",
        render(text) {
          return {
            children: (
              <div>
                {text.match(REG) ? (
                  <div>
                    <b>{text}</b>{" "}
                    <img
                      className="alert-gif"
                      src={require("../../assets/image/new-icon-gif-3.jpg")}
                      alt="loading..."
                    />
                  </div>
                ) : (
                  <div>{text}</div>
                )}
              </div>
            ),
          };
        },
        onCell: () => {
          return {
            style: {
              minWidth: 130,
              maxWidth: 130,
            },
          };
        },
      },
      // {
      //   title: "Location",
      //   dataIndex: "alert_location",
      //   align: "center",
      //   width: "8%",
      //   render: (data) => (
      //     <span className="action-span">
      //       <Link
      //         to={{
      //           pathname: `/vehicle-map/lat:${data[0]};lon:${data[1]}`,
      //           locationValue: data,
      //         }}
      //       >
      //         <img
      //           height="35px"
      //           src={require("../../assets/image/mapicon.png")}
      //           onClick={(e) => this.handleEvents(e)}
      //           alt="loading..."
      //         />
      //       </Link>
      //     </span>
      //   ),
      // },
      // {
      //   title: "Vehicle Report",
      //   dataIndex: "REG_NUMBER",
      //   align: "center",
      //   width: "8%",
      //   render: (REG_NUMBER) => (
      //     <span className="action-span">
      //       <Link
      //         to={{
      //           pathname: `/vehicle-details/${REG_NUMBER[0]}`,
      //         }}
      //       >
      //         <img
      //           height="35px"
      //           src={require("../../assets/image/report.png")}
      //           onClick={(e) => this.handleEvents(e)}
      //           alt="loading..."
      //         />
      //       </Link>
      //     </span>
      //   ),
      // },
    ];
  }
  handleEvents(e) {
    e.stopPropagation();
  }
  componentDidMount() {


    // // this.getAlertDropdown();
    this.setState({ loader: true });
    socket = socketIOClient(`${process.env.REACT_APP_BACKEND_URL}/getAlerts`);
    socket.on("reconnecting", () => {
      this.setState({ loader: false });
    });
    socket.on("alert", async (data) => {
      this.setState({ loader: false });
      await this.setState({
        dataFromSocket: data.data,
      });
      this.filterAlertList();
    });
  }

  filterAlertList = async () => {
    let alerts = this.state.dataFromSocket
      .filter((alert) => {
        let keep = true;

        if (this.state.searchFilterVal) {
          keep =
            keep &&
            alert?.device?.toLowerCase().includes(this.state.searchFilterVal?.toLowerCase());
        }
        return keep;
      })
      .map((item) => 
        ({
        // ALERT_CREATE_TIME: moment(item.alert_timestamp, 'HH:mm:ss.SSSSSS').fromNow(),
        // ALERT_CREATE_TIME: alertTime.clone().add(5, 'hours').add(30, 'minutes');
        ALERT_CREATE_TIME: moment(item.alert_timestamp, 'YYYY-MM-DD HH:mm:ss').fromNow(),
        ALERT_TYPE: item.alert_type,
        ALERT_VALUE: item.alert_value,
        CUSTOMER_NAME: item.CUSTOMER_NAME,
        CityName: item.CityName,
        VEHICLE_MAKE123: item.VEHICLE_MAKE123,
        NUMBER: [item.customer_name, item.mobile_number],
        DESCRIPTION: item.description,
        alert_location: [item.latitude, item.longitude],
        VEHICLE_TYPE: item.VEHICLE_TYPE,
        CRITICALITY: item.criticality,
        SOP_DESCRIPTION: item.SOP_DESCRIPTION,
        REG_NUMBER: [item.device, item.model],
        VEHICLE_MAKE: item.VEHICLE_MAKE,
      }));

    this.setState({
      dataSource: alerts.slice(0, 50),
      dataSourceCopy: alerts,
    });
  };
  componentWillUnmount() {
    socket.disconnect();
    this.setState({ firstOccurance: true });
  }


  fullscreen = () => {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  };

  handleChange = async (type, value) => {
    switch (type) {
      case "search":
        await this.setState({
          searchFilterVal: value,
        });
        break;
    }
    this.filterAlertList();
  };

  handleSearchValChange = async (e) => {
    await this.setState({
      searchFilterVal: e.target.value,
    });
    this.filterAlertList();
  };

  handleScroll = (e) => {
    let element = e.target;
    let cal = element.scrollTop / element.scrollHeight;
    let total = cal * 100;

    if (total > 70.0 && total < 80.8) {
      if (this.state.end < this.state.dataSourceCopy.length) {
        counter++;
        let param = this.state.dataSourceCopy;
        let start = counter;
        let end = counter * 50;
        let dataPush = [];
        for (let i = start; i <= end; i++) {
          if (param[i] == undefined) {
          } else {
            dataPush.push(param[i]);
          }
        }
        this.setState({
          count: this.state.count + 1,
          dataSource: dataPush,
          end: end,
        });
      }
    }
  };

  handlereload = async () => {
    await this.setState({
      cityFilterVal: "",
      makeFilterVal: "",
      typeFilterVal: "",
      searchFilterVal: "",
    });
    this.filterAlertList();
  };
  render() {
    const {
      loader,
      dataSource,
    } = this.state;
    return (
      <div>
        <p></p>
        <div className="alignItems">

          <div className={"dropdown-sec-alert"}>
            {/* {array[5] ? */}

            <Search
              // value={selectValues[5]}
              placeholder="Device number"
              value={this.state.searchFilterVal}
              onSearch={(e) => {
                this.handleChange("search", e);
              }}
              onChange={(e) => {
                this.handleSearchValChange(e);
              }}
              style={{ width: 200 }}
            />
          </div>{" "}
          {/* <Link> */}
            <img
              alt="refresh"
              onClick={this.handlereload}
              className="refreshBtn"
              height="25px"
              src={require("../../assets/image/refresh.png")}
            />
          {/* </Link> */}
        </div>
        {loader ? (
          <p class="loaderItem">
            {" "}
            <Spin size="large" />
          </p>
        ) : (
          <div id="fullScreenDiv">
            <div
              onScroll={this.handleScroll}
              id="juscroll"
              className="juscroll"
            >
              <Table
                onRow={(record, index) => ({
                  onClick: (event) => {
                    this.fullscreen();
                  },
                })}
                columns={this.columns}
                dataSource={dataSource}
                owKey="id"
                pagination={false}
                scroll={{ y: "calc(100vh - 4em)" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default AlertView;
