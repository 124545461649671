import React, { useState, useRef } from "react";
import useSupercluster from "use-supercluster";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { Link } from "react-router-dom";

const Marker = ({ children }) => children;

let infoData;

const Map = (props) => {
  const [innerCircle, setInnerCircle] = useState(null);
  const [infoId, setInfoId] = useState(-1);
  const [outerCircle, setOuterCircle] = useState(null);
  const [maps, setMaps] = useState(null);

  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const points = props.vehicles;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 50, maxZoom: 9 },
  });

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <GoogleMapReact
        options={getMapOptions}
        onClick={() => {
          if (innerCircle) {
            setInnerCircle(innerCircle.setMap(null));
          }
          if (outerCircle) {
            setOuterCircle(outerCircle.setMap(null));
          }
          setInfoId(-1);
        }}
        bootstrapURLKeys={{ key: 'AIzaSyBYyAVta6hHJwvS9vWx6rCMrHy_xPZiuL4' }}
        defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
        defaultZoom={10}
        zoom={props.zoomVal}
        center={props.zoomCoord}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          setMaps(maps);
        }}
        onChange={({ zoom, bounds, center }) => {
          props.setZoomAndCoord(zoom, center);

          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >

        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties;
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <button
                  className="cluster-marker"
                  style={{
                    width: `${20 + (pointCount / points.length) * 30}px`,
                    height: `${20 + (pointCount / points.length) * 30}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </button>
              </Marker>
            );
          }
          if (String(cluster.properties.category) === "hasAlerts") {
            return (
              <Marker
                key={`vehicle-${cluster.properties.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div className="container">
                  <button
                    className="box vehicle-marker"
                    onClick={() =>
                      setCircle(
                        innerCircle,
                        setInnerCircle,
                        outerCircle,
                        setOuterCircle,
                        maps,
                        latitude,
                        longitude,
                        mapRef,
                        setInfoId,
                        cluster
                      )
                    }
                  >
                    <img src="/red_icon1.png" alt="vehicle" />
                  </button>
                  {getInfo(cluster, infoId)}
                </div>
              </Marker>
            );
          } else if (
            String(cluster.properties.status).toLowerCase() === "parked"
          ) {
            return (
              <Marker
                key={`vehicle-${cluster.properties.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div className="container">
                  <button
                    className="box vehicle-marker"
                    onClick={() =>
                      setCircle(
                        innerCircle,
                        setInnerCircle,
                        outerCircle,
                        setOuterCircle,
                        maps,
                        latitude,
                        longitude,
                        mapRef,
                        setInfoId,
                        cluster
                      )
                    }
                  >
                    <img src="/grey_icon1.png" alt="vehicle" />
                  </button>
                  {getInfo(cluster, infoId)}
                </div>
              </Marker>
            );
          } else {
            return (
              <Marker
                key={`vehicle-${cluster.properties.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div className="container">
                  <button
                    className="box vehicle-marker"
                    onClick={() =>
                      setCircle(
                        innerCircle,
                        setInnerCircle,
                        outerCircle,
                        setOuterCircle,
                        maps,
                        latitude,
                        longitude,
                        mapRef,
                        setInfoId,
                        cluster
                      )
                    }
                  >
                    <img src="/green_icon1.png" alt="vehicle" />
                  </button>
                  {getInfo(cluster, infoId)}
                </div>
              </Marker>
            );
          }
        })}
      </GoogleMapReact>
    </div>
  );
};

let getInfoData = async (vehicleId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/fetch_vehicleData`,
        {
          deviceId: String(vehicleId),
        }
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};


let getInfo = (cluster, infoId) => {
  return (
    cluster.properties.id === infoId &&
    infoData && (
      <div
        id="tooltip"
        className="box stack-top tooltip"
        style={{ width: "max-content" }}
      >
        <div className="tooltip-arrow" />
        {/* <div className="tooltip-label"> */}
        <div>Name: {infoData.CustomerName}</div>
        <div> Mobile No: {infoData.CustomerNumber}</div>
        <div>Device No: {infoData.deviceNumber}</div>
        <div>Make Model: {infoData.MakeModelName}</div>
        <div>
          <Link
            to={{
              pathname: `/vehicle-details/${infoData.deviceNumber}`,
            }}
          >
            View Details on Report
          </Link>
        </div>
        {infoData.Alert && infoData.Alert[0] !== null && (
          infoData.Alert.map((alert, index) => (
            <div key={index}>Alert: {alert}</div>
          ))
        )}
      </div>
    )
  );
};

let setCircle = async (
  innerCircle,
  setInnerCircle,
  outerCircle,
  setOuterCircle,
  maps,
  latitude,
  longitude,
  mapRef,
  setInfoId,
  cluster
) => {
  if (innerCircle) {
    setInnerCircle(innerCircle.setMap(null));
  }
  if (outerCircle) {
    setOuterCircle(outerCircle.setMap(null));
  }
  setInnerCircle(
    (innerCircle = new maps.Circle({
      strokeColor: "red",
      strokeOpacity: 0,
      strokeWeight: 5,
      fillColor: "#87CEFA",
      fillOpacity: 0.4,
      map: mapRef.current,
      center: { lat: latitude, lng: longitude },
      radius: 5000,
    }))
  );
  setOuterCircle(
    (outerCircle = new maps.Circle({
      strokeColor: "red",
      strokeOpacity: 0,
      strokeWeight: 5,
      fillColor: "#c0c0c0",
      fillOpacity: 0.4,
      map: mapRef.current,
      center: { lat: latitude, lng: longitude },
      radius: 10000,
    }))
  );
  if (!infoData || infoData.deviceId !== cluster.properties.deviceId) {
    infoData = undefined;
    infoData = await getInfoData(cluster.properties.deviceId);
    infoData.deviceId = cluster.properties.deviceId;
  }

  await setInfoId(cluster.properties.id);
};

let getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    zoomControl: true,
    clickableIcons: false,
  };
};

export default Map;
