import React, { useState, useEffect } from "react";
import { Button, Space, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import "./masters.css";


const DeviceTable = ({ data, cancelModal }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceData, setDeviceData] = useState([]);

    const [formData, setFormData] = useState({
        DIN: '',
        errors: {
            DIN: ''
        }
    });

    useEffect(() => {
        listDevices();
        setModalVisible(data);
    }, [data]);

    const listDevices = () => {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/list_devices`,
          )
          .then((result) => {
            if (Array.isArray(result.data.data) && result.data.data.length) {
              setDeviceData(result.data.data);
            }
          })
          .catch((error) => { });
      }


    const addDevice = (payload) => {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/create_device`,
            payload
          )
          .then((result) => {
            listDevices();
            handleCancel();
          })
          .catch((error) => { });
      }
    
      const editDevice = (payload) => {
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/update_device`,
            payload
          )
          .then((result) => {
            listDevices();
            handleCancel();
          })
          .catch((error) => { });
      }
    
      const deleteDevice = (deviceId) => {
        axios
          .delete(
            `${process.env.REACT_APP_BACKEND_URL}/c4-telematics/api/delete_device/` + deviceId,
          )
          .then((result) => {
            listDevices();
          })
          .catch((error) => { });
      }

    const showModal = (record) => {
        let errors = { ...formData.errors };
        setModalVisible(true);
        setSelectedDevice(record);
        setFormData(record);
        setFormData({
            ...formData,
            ['DIN']: record.DIN
        });
    }

    const handleDelete = (record) => {
        deleteDevice(record.device_id);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setSelectedDevice(null);
        cancelModal();
        setFormData({
            ...formData,
            ['DIN']: ''
        });
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        let errors = { ...formData.errors };

        switch (name) {
            case 'DIN':
                errors.DIN = value.length < 3 ? 'DIN must be at least 3 characters long' : '';
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            [name]: value,
            errors: { ...errors }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        let valid = true;
        Object.values(formData.errors).forEach((error) => {
            if (error.length > 0) {
                valid = false;
            }
        });

        if (valid) {
            console.log('Form submitted:', formData);
            if (selectedDevice) {
                let payload = {
                    "DIN": formData.DIN,
                    "id": selectedDevice.device_id,
                }
                editDevice(payload);
            } else {
                let payload = {
                    "DIN": formData.DIN,
                }
                addDevice(payload);
            }
            // You can perform further actions here, like sending the form data to a server
        } else {
            console.error('Invalid form');
        }
    };

    const deviceColumns = [
        {
          title: 'DIN',
          dataIndex: 'DIN',
          key: 'DIN',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button type="primary" onClick={() => showModal(record)} icon={<EditOutlined />} />
              <Button type="danger" onClick={() => handleDelete(record)} icon={<DeleteOutlined />} />
            </Space>
          ),
        },
      ];

    return (
        <div>
            <Table dataSource={deviceData} columns={deviceColumns} />

            <Modal
                title={selectedDevice ? 'Edit Device' : 'Add Device'}
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="customer_name"> DIN:</label>
                        <input
                            type="text"
                            id="DIN"
                            name="DIN"
                            value={formData.DIN}
                            onChange={handleChange}
                            required
                        />
                        {formData.errors.DIN.length > 0 && (
                            <span className="error">{formData.errors.DIN}</span>
                        )}
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </Modal>
        </div>

    );


};

export default DeviceTable;